import React from 'react';
import { ReactComponent as Dot } from 'assets/images/icons/dot.svg';
import { Text } from '@scriptaddicts/yamm-ui-components';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { Container, ItemGroup, ItemGroupItem, ItemIcon } from './styles';

const UserSettingsInfo = () => {
	const { user } = useWorkspaceContext();
	
	const adminInfoItems = [
		'Upgrade them at a pro-rated price and give them access to full quota and advanced features.',
		'Schedule their downgrade at next renewal.',
	];
	const ownerInfoItems = [...adminInfoItems, 
		'Change their role between User or Admin (paid feature).',
	];
	const userSettingsInfoItems = user?.role === 'OWNER' ? ownerInfoItems : adminInfoItems;  
	const userSettingsInfo = userSettingsInfoItems
		.map(info => <ItemGroupItem key={info}><ItemIcon as={Dot} /><Text>{info}</Text></ItemGroupItem>);
  return (
    <Container>
		<Text>Select users to:</Text>
		<ItemGroup>{userSettingsInfo}</ItemGroup>
    </Container>
  );
};

export default UserSettingsInfo;
