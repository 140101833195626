import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Layout from 'containers/Common/Layout';
import TemplatesProvider from 'data/context/TemplatesContext';
import {
  useWorkspaceContext,
  WorkspaceContextProvider,
} from 'data/context/WorkspaceContext';
import LoadingScreen from 'components/LoadingScreen';
import useWorkspaceRouter from 'utils/router/useWorkspaceRouter';
import Checkout from 'containers/Checkout';
import { useClickEventLogger } from 'data/hooks/useClickEventLogger';
import { useClarityWorkspaceLegacyPlanTag } from 'data/hooks/useClarity';
import MailMerges from './MailMerges';
import Unsubscriptions from './Unsubscriptions';
import TeamReport from './TeamReport';
import { useConnect } from './connect';
import Templates from './Templates';
import { StackDriverLoggingProvider } from '../../data/context/StackDriverLogging';
import Workspaces from './Workspaces';
import { Invitations } from './Workspaces/Invitations';
import { DisabledUser } from './Workspaces/DisabledUser';
import Bounces from './Bounces';
import { usePageRestrictions } from '../../data/hooks/restrictions/useRestrictions';
import BillingRedirection from './BillingRedirection';

const WorkspaceRoutes = () => {
  const { url, path, isLoading } = useWorkspaceRouter();
  const { user, details } = useWorkspaceContext();
  const { bouncesPage } = usePageRestrictions();
  useClickEventLogger();
  useClarityWorkspaceLegacyPlanTag(details?.legacyType);

  const location = useLocation();
  const queryParams = location.search;

  if (isLoading) return <LoadingScreen />;
  if (user?.status === 'DISABLED')
    return (
      <Layout>
        <DisabledUser />
      </Layout>
    );

  return (
    <Switch>
      <Route component={Checkout} path={`${path}/checkout`} />
      <Route path="*">
        <Layout>
          <Switch>
            <Route component={MailMerges} path={`${path}/mail-merges`} />
            <Route component={TeamReport} path={`${path}/team-report`} />
            <Route
              component={Unsubscriptions}
              path={`${path}/unsubscriptions`}
            />
            {!bouncesPage && (
              <Route component={Bounces} path={`${path}/bounces`} />
            )}
            <Route component={Templates} path={`${path}/templates`} />
            <Route component={Workspaces} path={`${path}/space`} />
            <Route component={DisabledUser} path={`${path}/disabled`} />
            <Route path={`${path}/settings/billing`}>
              <Redirect to={`${url}/space/billing${queryParams}`} />
            </Route>
            <Route path="*">
              <Redirect to={`${url}/mail-merges`} />
            </Route>
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
};

const Main = () => {
  const { isTesting, me } = useConnect();

  if (isTesting && !me) return null;

  return (
    <TemplatesProvider>
      <StackDriverLoggingProvider>
        <WorkspaceContextProvider>
          <Switch>
            <Route path="/invitations/:invitationToken/:status?">
              <Layout>
                <Invitations />
              </Layout>
            </Route>
            <Route path="/settings/billing">
              <BillingRedirection />
            </Route>
            <Route path="*">
              <WorkspaceRoutes />
            </Route>
          </Switch>
        </WorkspaceContextProvider>
      </StackDriverLoggingProvider>
    </TemplatesProvider>
  );
};

export default Main;
