import React, { FC } from 'react';

import { ReactComponent as ExternalLinkIcon } from 'assets/images/icons/external-link.svg';
import { ReactComponent as CancelIcon } from 'assets/images/icons/cancel.svg';

import testingVar from 'utils/testingVar';

import { Dialog, Spinner } from '@scriptaddicts/yamm-ui-components';
import {
  Container,
  TitleContainer,
  Title,
  TitlePlaceholder,
  Error,
  ErrorIcon,
  WarningIcon,
  Divider,
  Content,
  Info,
  Column,
  Label,
  Data,
  DataPlaceholder,
  Link,
  IconWrapper,
  LinkIcon,
  CancelButton,
} from './styles';

import { useConnect } from './connect';
import { Props } from './types';
import ScheduledCampaignError from './errors';
import { isErrorStatus } from '../../../../../utils/scheduledCampaigns';

const ScheduledCampaignDetail: FC<Props> = (props) => {
  const {
    scheduledMailMerge,
    deliveryDate,
    isCanceling,
    onCancel,
    dialog,
    isOutOfQuota,
  } = useConnect(props);

  return (
    <Dialog close="both" {...dialog}>
      <Container>
        <TitleContainer>
          {scheduledMailMerge?.title ? (
            <Title>{scheduledMailMerge?.title}</Title>
          ) : (
            <TitlePlaceholder />
          )}
        </TitleContainer>
        {scheduledMailMerge &&
          isErrorStatus(scheduledMailMerge.status) &&
          !!scheduledMailMerge.status && (
            <Error $variant="error">
              <ErrorIcon />
              {ScheduledCampaignError[scheduledMailMerge.status](
                scheduledMailMerge,
              )}
            </Error>
          )}
        {scheduledMailMerge && isOutOfQuota && (
          <Error $variant="warning">
            <WarningIcon />
            <span>
              Your mail merge could not be sent in full because your sending
              quota was insufficient. It will resume as soon as your quota
              resets.
            </span>
          </Error>
        )}
        <Divider />
        <Content data-test-id={testingVar('scheduled-details')}>
          <Info>
            <Column>
              <Label>emails</Label>
              {scheduledMailMerge ? (
                <Data>{scheduledMailMerge.numberOfEmailsToSend}</Data>
              ) : (
                <DataPlaceholder />
              )}
            </Column>
            <Column>
              <Label>Delivery date</Label>
              <Data>
                {isOutOfQuota ? 'Upon quota reset' : deliveryDate || '-'}
              </Data>
            </Column>
          </Info>
        </Content>
        <Link
          href={scheduledMailMerge?.spreadsheetLink || '#'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper>
            <ExternalLinkIcon />
          </IconWrapper>
          Open in Google Sheets
          <LinkIcon />
        </Link>
        <CancelButton
          $variant='error'
          data-test-id={testingVar('submit-cancel')}
          onClick={onCancel}
          disabled={!scheduledMailMerge || isCanceling}
        >
          <IconWrapper>
            {isCanceling ? (
              <Spinner size={20} />
            ) : (
              <CancelIcon />
            )}
          </IconWrapper>
          <span>Cancel mail merge</span>
        </CancelButton>
      </Container>
    </Dialog>
  );
};

export default ScheduledCampaignDetail;
