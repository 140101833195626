import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const ItemIcon = styled.div`
`;

export const ItemGroupItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0px 4px 8px;
  gap: 0px 8px;
`;

export const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;
