import { useEffect } from 'react';
import { WorkspaceLegacyPlanType } from 'model/workspace';
import { ClarityUtils } from '../../utils/clarityUtils';

export const useClarity = () => {
  useEffect(() => {
    ClarityUtils.setup();
  }, []);
};

export const useClarityWorkspaceLegacyPlanTag = (
  legacyPlan: WorkspaceLegacyPlanType | undefined,
) => {
  useEffect(() => {
    if (!legacyPlan) {
      return;
    }
    const individualPlans = ['PERSONAL', 'PROFESSIONAL'];
    const teamPlans = ['TEAM', 'UNLIMITED'];
    if (individualPlans.includes(legacyPlan)) {
      ClarityUtils.tag('legacy_plan', legacyPlan.toLowerCase());
    } else if (teamPlans.includes(legacyPlan)) {
      ClarityUtils.tag('legacy_plan', 'team');
    }
  }, [legacyPlan]);
};
