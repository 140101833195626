import React from 'react';

import StaticLayout from 'containers/Common/StaticLayout';
import testingVar from 'utils/testingVar';
import { Prompt } from 'components/Prompt';
import { GoogleButton, Spinner } from '@scriptaddicts/yamm-ui-components';
import Error from './Error';
import Success from './Success';
import { useConnect } from './connect';
import {
  Text,
  Error as ErrorMessage,
  ConfirmButton,
  LoginMessage,
} from './styles';

const StopAutomaticRenewal = () => {
  const {
    isLoggedIn,
    clientId,
    isLoading,
    onLogin,
    cancelRenewal,
    error,
    success,
    me,
    replace,
  } = useConnect();

  if (
    me &&
    (('license' in me && me.license.paymentSource !== 'PAYPAL') ||
      !('license' in me))
  ) {
    replace('/');
    return null;
  }

  if (success === '0') {
    return <Error />;
  }

  if (success === '1') {
    return <Success />;
  }

  const textContent = (
    <>
      This action is permanent, if you wish to re-enable the automatic renewal,
      you have to purchase a new plan from our{' '}
      <a
        href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing?utm_source=spaces_webapp`}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        Pricing page
      </a>
    </>
  );

  return (
    <StaticLayout data-test-id={testingVar('stop-automatic-renewal')}>
      <Prompt
        title="Are you sure you want to stop the automatic renewal?"
        description={
          <>
            {clientId && !isLoading && !isLoggedIn ? (
              <>
                <LoginMessage data-test-id={testingVar('login')}>
                  Sign in with your Google account to stop the automatic
                  renewal.
                </LoginMessage>
                <GoogleButton onClick={onLogin}>
                  Sign in with Google
                </GoogleButton>
              </>
            ) : null}
            {isLoading ? <Spinner size={36} /> : null}
            {error && !isLoading ? <ErrorMessage>{error}</ErrorMessage> : null}
            {isLoggedIn && !isLoading ? (
              <ConfirmButton
                aria-label="Confirm cancellation"
                onClick={cancelRenewal}
                data-test-id={testingVar('confirm-cancellation')}
              >
                Confirm cancellation
              </ConfirmButton>
            ) : null}
          </>
        }
      >
        <Text>{textContent}</Text>
      </Prompt>
    </StaticLayout>
  );
};

export default StopAutomaticRenewal;
