import {
  ScheduledCampaignInput,
  ScheduledCampaign,
  ScheduledCampaignDetail,
} from 'model/scheduledCampaign';

export const transformScheduledCampaigns = (
  campaigns: ScheduledCampaignInput[],
): ScheduledCampaign[] => {
  return campaigns.map((campaign) => {
    const breakPoint = campaign.mergeSourceSheetKey.lastIndexOf('-');
    const spreadsheetId = campaign.mergeSourceSheetKey.slice(0, breakPoint);
    const sheetId = campaign.mergeSourceSheetKey.slice(breakPoint + 1);
    return {
      id: campaign.mergeSourceSheetKey,
      title: `${campaign.mergeSourceSpreadSheetName} - ${campaign.mergeSourceSheetName}`,
      spreadsheetName: campaign.mergeSourceSpreadSheetName,
      createdDateTime: Number(campaign.createTimestamp),
      mergeDate: campaign.nextScheduledRunTimestamp
        ? Number(campaign.nextScheduledRunTimestamp)
        : undefined,
      mergeDateTime: campaign.nextScheduledRunTimestamp
        ? Number(campaign.nextScheduledRunTimestamp)
        : undefined,
      numberOfEmailsToSend: campaign.eventCounts?.toSendCount ?? 0,
      spreadsheetLink: `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=${sheetId}`,
      mergeTime: campaign.nextScheduledRunTimestamp
        ? Number(campaign.nextScheduledRunTimestamp)
        : undefined,
      status: campaign.status,
    };
  });
};

export const transformScheduledCampaignDetail = (
  campaign: ScheduledCampaignInput,
): ScheduledCampaignDetail => {
  const breakPoint = campaign.mergeSourceSheetKey.lastIndexOf('-');
  const spreadsheetId = campaign.mergeSourceSheetKey.slice(0, breakPoint);
  const sheetId = campaign.mergeSourceSheetKey.slice(breakPoint + 1);
  return {
    id: campaign.mergeSourceSheetKey,
    spreadsheetId,
    sheetId,
    mergeTime: campaign.nextScheduledRunTimestamp
      ? Number(campaign.nextScheduledRunTimestamp)
      : undefined,
    createdTime: Number(campaign.createTimestamp),
    sheetName: campaign.mergeSourceSheetName,
    spreadsheetName: campaign.mergeSourceSpreadSheetName,
    numberOfEmailsSent: campaign.eventCounts?.sentCount ?? 0,
    numberOfEmailsToSend: campaign.eventCounts?.toSendCount ?? 0,
    status: campaign.status,
    error: campaign.errorDetails?.error,
    errorDetails: campaign.errorDetails?.errorDetails,
    recoverableErrorRetries: campaign.errorDetails?.recoverableErrorRetries,
    recoverableErrorLastRetryTimestamp:
      campaign.errorDetails?.recoverableErrorLastRetryTimestamp ? Number(campaign.errorDetails?.recoverableErrorLastRetryTimestamp) : undefined,
    title: `${campaign.mergeSourceSpreadSheetName} - ${campaign.mergeSourceSheetName}`,
    mergeDate: campaign.nextScheduledRunTimestamp
      ? Number(campaign.nextScheduledRunTimestamp)
      : undefined,
    mergeDateTime: campaign.nextScheduledRunTimestamp
      ? Number(campaign.nextScheduledRunTimestamp)
      : undefined,
    createdDate: Number(campaign.createTimestamp),
    createdDateTime: Number(campaign.createTimestamp),
    spreadsheetLink: `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=${sheetId}`,
  };
};
