import { WorkspaceMemberInput, WorkspaceMember } from 'model/workspaceMembers';
import { getRecipientsQuotaPerDay } from 'utils/workspace';

export const transformWorkspaceMembers = (
  members: WorkspaceMemberInput[],
): WorkspaceMember[] => {
  return members.map(
    (member) =>
      ({
        id: member.userId,
        name: member.userName,
        email: member.memberEmail,
        role: member.userRole,
        plan: member.userPlan,
        status: member.memberStatus,
        trial:
          member.freeTrialEndTimestamp === 9223372036825200000
            ? -1
            : Number(member.freeTrialEndTimestamp),
        createTimestamp: Number(member.createTimestamp),
        isPersonal: member.isPersonal,
        isUserInDomain: member.isUserInDomain,
        isScheduledToBeDowngraded: !!member.isScheduledToBeDowngraded,
        quota: getRecipientsQuotaPerDay(member.userPlan)
      } as WorkspaceMember),
  );
};
