import React from 'react';
import { Column } from 'components/ResponsiveTable/types';
import { WorkspaceMember } from 'model/workspaceMembers';
import {
  TableCell,
  Icon,
  Tooltip,
  TableCellValueGroup,
} from '@scriptaddicts/yamm-ui-components';
import { formatShortMonthDayYear } from 'utils/dates';
import { plurify } from 'utils/plurify';
import { TableCellValue, Badge, CellContentWithIcon } from './styles';

export const getColumns = ({
  isMobile,
  count,
  domain,
  usersDowngradeDate,
}: {
  isMobile: boolean;
  count?: number;
  domain?: string;
  usersDowngradeDate: number;
}) =>
  [
    {
      Header: 'User',
      accessor: 'name',
      colSpan: isMobile ? 5 : 2,
      group: isMobile,
      Cell: ({ value, row }) =>
        isMobile ? (
          <>
            <TableCell colSpan={2}>
              <TableCellValue width="semi-bold">
                {row.original.name}
              </TableCellValue>
            </TableCell>
            <TableCell colSpan={3}>{row.original.email}</TableCell>
          </>
        ) : (
          <TableCellValue width="semi-bold">{value}</TableCellValue>
        ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      colSpan: 3,
      Cell: ({ value }) => (
        <TableCellValue>{!isMobile ? value : undefined}</TableCellValue>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      colSpan: 1,
      Cell: ({ value }) => (
        <TableCellValue>
          {value === 'OWNER' && 'Owner'}
          {value === 'ADMIN' && 'Admin'}
          {value === 'MEMBER' && 'User'}
        </TableCellValue>
      ),
    },
    {
      Header: 'Type',
      accessor: 'plan',
      colSpan: 2,
      Cell: ({ value, row }) => {
        const { trial, isScheduledToBeDowngraded } = row.original;
        if (trial) {
          return (
            <CellContentWithIcon>
              <Badge type="success" responsive>
                Trial
              </Badge>
              <Tooltip
                tip={
                  trial === -1
                    ? 'Unlimited trial license.'
                    : `Expires on ${formatShortMonthDayYear(new Date(trial))}.`
                }
              >
                <Icon name="info" />
              </Tooltip>
            </CellContentWithIcon>
          );
        }
        if (value === 'FREE') {
          return (
            <Badge type="info" responsive>
              Free
            </Badge>
          );
        }
        return (
          <CellContentWithIcon>
            <Badge type="success" responsive>
              Paid
            </Badge>
            {isScheduledToBeDowngraded && (
              <Tooltip
                tip={`This user will be downgraded to free on ${formatShortMonthDayYear(
                  usersDowngradeDate,
                )}.`}
              >
                <Icon name="info" />
              </Tooltip>
            )}
          </CellContentWithIcon>
        );
      },
    },
    {
      Header: 'Quota',
      accessor: 'quota',
      colSpan: 2,
      Cell: ({ value, row }) => {
        return (
          <CellContentWithIcon>
            <Badge type={row.original.plan === 'FREE'? "info": "success"} responsive>
            Up to {value}
            </Badge>
          </CellContentWithIcon>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      colSpan: 1,
      Cell: ({ value, row }) => {
        if (value === 'ACTIVE' || value === 'ACCEPTED') {
          return <TableCellValue variant="success">Active</TableCellValue>;
        }
        if (value === 'PENDING') {
          return <TableCellValue>Pending</TableCellValue>;
        }
        if (value === 'DISABLED') {
          return (
            <TableCellValueGroup variant="warning">
              <span>Disabled</span>
              {(() => {
                if (domain && !row.original.isUserInDomain) {
                  return (
                    <Tooltip
                      tip={`Your current plan only allows user from domain ${domain}.`}
                    >
                      <Icon name="alert-triangle" />
                    </Tooltip>
                  );
                }
                if (count !== undefined) {
                  return (
                    <Tooltip
                      tip={`Your current plan does not allow more than ${count} free ${plurify(
                        count,
                        'user',
                        'users',
                      )}.`}
                    >
                      <Icon name="alert-triangle" />
                    </Tooltip>
                  );
                }
                return undefined;
              })()}
            </TableCellValueGroup>
          );
        }
        return (
          <TableCellValue style={{ textTransform: 'capitalize' }}>
            {value.toLowerCase()}
          </TableCellValue>
        );
      },
    },
  ] as Column<WorkspaceMember>[];
