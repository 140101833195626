import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { PriceProps } from './types';

const PriceContainer = styled.div`
  display: flex;
`;

const CurrencyText = styled.span`
  align-self: start;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.suvaGrey};
  font-feature-settings: 'clig' off, 'liga' off;
  position: absolute;
  transform: translate(-100%, 20%);
`;

const PriceText = styled.span`
  align-self: center;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.cowboy};
`;

const BillingPeriodText = styled.span`
  align-self: flex-end;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.suvaGrey};
`;

export const priceToStringForAnnualPaymentMonthlyDisplay = (price: number) => {
  return new Intl.NumberFormat('en', { maximumFractionDigits: 2 })
    .format(price)
    .replace(/,/g, '');
};

const Price: FC<PriceProps> = ({
  price,
  currency,
  monthlyPrice,
  monthlyPriceUnit,
}) => (
  <>
    <PriceContainer>
      <CurrencyText>{currency}</CurrencyText>
      <PriceText>
        {priceToStringForAnnualPaymentMonthlyDisplay(monthlyPrice)}
      </PriceText>
      <BillingPeriodText>/{monthlyPriceUnit}</BillingPeriodText>
    </PriceContainer>
    <PriceContainer>
      <BillingPeriodText>
        <span style={{ fontWeight: 600 }}>
          {currency}
          {price}
        </span>{' '}
        billed annually
      </BillingPeriodText>
    </PriceContainer>
  </>
);

export default memo(Price);
