import { useEffect, useMemo, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { FormikHelpers } from 'formik';
import { captureException } from '@sentry/react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  logError,
  logStripeSessionCreatedEvent,
} from 'utils/userJourneyLogging';
import { useCheckoutContext } from '../../../data/context/CheckoutContext';
import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import { validationSchema } from './validation';
import { CheckoutFormValues } from '../../../data/context/CheckoutContext/types';
import {
  useComputeStripePaymentInfo,
  useCreateStripeCheckoutSession,
} from '../../../data/hooks/useWorkspaceBilling';
import {
  mapCheckoutFormToCheckoutRequest,
  mapTableUserToPaidUser,
} from '../../../utils/checkout/mappers';
import { getPaidUsers } from '../../../utils/checkout';
import {
  getDefaultCountry,
  getErrorMessage,
  getPurchaseType,
  hasToUpgradeLegacySubscription,
} from './utils';
import useWorkspaceLocation from '../../../utils/router/useWorkspaceLocation';
import { PLAN, INTERVAL } from '../../../data/queryParams';
import { CHECKOUT_SESSION_FORM_KEY } from '../../../data/constants';
import { useAvailableUsers } from '../BillingOptions/hooks/useAvailableUsers';
import EUcountries from './eu_countries.json';
import { useMe } from '../../../data/hooks/useMe';

const useCheckQueryParams = () => {
  const { replace } = useHistory();
  const { url } = useWorkspaceLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has(PLAN) && !searchParams.has(INTERVAL)) {
      replace(`${url}/checkout`);
    }
  }, [url]);
};

export const useConnect = () => {
  const stripe = useStripe();
  const {
    checkoutForm,
    setCheckoutForm,
    plan,
    billingInterval,
  } = useCheckoutContext();
  const { availableUsers } = useAvailableUsers();
  const { pathname } = useWorkspaceLocation();
  const { search } = useLocation();
  const {
    id,
    details: workspaceDetails,
    user,
    otherWorkspaces,
    isLoading: isLoadingWorkspace,
  } = useWorkspaceContext();
  const { data: userData } = useMe();
  const [country, setCountry] = useState<string | undefined>(
    checkoutForm.country,
  );

  const isEuropeanCountry = useMemo(
    () => EUcountries.some((c) => c.id === country),
    [country],
  );

  const [isBusinessPurchase, setIsBusinessPurchase] = useState(
    checkoutForm.isBusinessPurchase,
  );
  const [redirecting, setRedirecting] = useState<boolean>(false);

  useEffect(() => {
    const handlePageHide = (event: PageTransitionEvent) => {
      if (event.persisted) {
        window.location.reload();
      }
    };
    window.addEventListener('pagehide', handlePageHide);
    return () => {
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, []);

  const paidUsers = useMemo(() => getPaidUsers(availableUsers), [
    availableUsers,
  ]);
  const [submitError, setSubmitError] = useState<string | undefined>();

  const {
    data: paymentInfo,
    isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfo(
    id,
    {
      country: getDefaultCountry(country),
      userUpdates: paidUsers.map(mapTableUserToPaidUser),
      billingInterval,
      purchaseType: getPurchaseType(isBusinessPurchase),
      workspacePlan: plan,
      upgradeLegacySubscription: hasToUpgradeLegacySubscription(
        workspaceDetails,
      ),
    },
    true,
  );

  const {
    mutateAsync,
    isLoading: isSubmitting,
  } = useCreateStripeCheckoutSession();

  useCheckQueryParams();

  const onSubmit = async (
    values: CheckoutFormValues,
    { setFieldError }: FormikHelpers<CheckoutFormValues>,
  ) => {
    setCheckoutForm(values);
    setSubmitError(undefined);
    const stripePlans = paymentInfo?.stripePlans;

    if (id && stripePlans) {
      const nbPaidUsers = paidUsers.length;
      const nbFreeUsers = 0;
      try {
        const data = await mutateAsync(
          mapCheckoutFormToCheckoutRequest({
            workspaceId: id,
            checkoutForm: values,
            stripePlans,
          }),
        );

        if (data && stripe) {
          logStripeSessionCreatedEvent({
            pagePath: pathname,
            search,
            user: userData,
            workspaceId: id,
            workspaceDetails,
            workspaceUser: user,
            otherWorkspaces,
            nbPaidUsers,
            nbFreeUsers,
            selectedPlan: plan,
            stripeCheckoutSessionId: data.stripeCheckoutSessionId,
          });
          setRedirecting(true);
          stripe
            .redirectToCheckout({
              sessionId: data.stripeCheckoutSessionId,
            })
            .finally(() => {
              setRedirecting(false);
            });
        }
        sessionStorage.setItem(
          CHECKOUT_SESSION_FORM_KEY,
          JSON.stringify(values),
        );
      } catch (e) {
        window.scrollTo(0, 0);
        captureException(e);
        setRedirecting(false);
        const { message, isField, key } = getErrorMessage(e);
        if (isField) {
          setFieldError(key, message);
        } else {
          setSubmitError(key);
        }
        logError({
          errorType: key,
          pagePath: pathname,
          search,
          workspaceId: id,
          nbPaidUsers,
          nbFreeUsers,
          selectedPlan: plan,
        });
      }
    } else {
      window.scrollTo(0, 0);
      captureException(new Error('Workspace ID of stripe plans are not found'));
      setSubmitError('Workspace ID of stripe plans are not found');
    }
  };

  return {
    workspaceDetails,
    isLoading: isLoadingWorkspace,
    validationSchema,
    checkoutForm,
    isEuropeanCountry,
    setCountry,
    isBusinessPurchase,
    setIsBusinessPurchase,
    onSubmit,
    isSubmitting: isSubmitting || redirecting,
    isFetchingPaymentInfo,
    submitError,
  };
};
